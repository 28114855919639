<template>
  <div class="DashboardManage">
    <AppBarLayout
      class="DashboardManage__app-bar"
      action-buttons-class="ml-16"
    >
      <template #header>
        {{ $t('dashboard.ManageDashboard') }}
      </template>

      <template #actions>
        <div class="DashboardManage__app-bar-actions d-flex flex-nowrap">
          <v-btn
            key="save"
            color="primary"
            depressed
            :disabled="ui.loading"
            :loading="ui.saving"
            @click="applyChanges"
          >
            {{ $t('dashboard.Save') }}
          </v-btn>
          <v-btn
            key="cancel"
            color="primary"
            class="ml-2"
            outlined
            :disabled="ui.loading || ui.saving"
            @click="revertChanges"
          >
            {{ $t('dashboard.Cancel') }}
          </v-btn>
        </div>
      </template>
    </AppBarLayout>

    <div
      class="DashboardManage__controls"
    >
      <div class="DashboardManage__tabs">
        <span
          v-for="cardTypeKey in model.rowOrder"
          :key="cardTypeKey"
          class="DashboardManage__tab-wrapper"
        >
          <v-btn
            class="DashboardManage__tab"
            :class="{
              'DashboardManage__tab--active': ui.currentTab === cardTypeKey,
            }"
            :disabled="ui.editRowOrder || ui.saving"
            text
            tile
            height="64"
            color="primary"
            @click="ui.currentTab = cardTypeKey"
          >
            {{ TAB_NAMES[cardTypeKey] || cardTypeKey }}
          </v-btn>

          <v-btn
            v-if="ui.editRowOrder && cardTypeKey !== last(model.rowOrder)"
            icon
            class="white elevation-1"
            small
            @click="swapRowWithNext(cardTypeKey)"
          >
            <v-icon
              size="24"
              v-text="'mdi-swap-horizontal'"
            />
          </v-btn>
        </span>
      </div>

      <div class="DashboardManage__row-order-controls">
        <v-btn
          text
          tile
          color="#0088FF"
          :disabled="ui.saving"
          class="DashboardManage__row-order-control"
          @click="ui.editRowOrder = !ui.editRowOrder"
        >
          {{ ui.editRowOrder ? $t('dashboard.Save') : $t('dashboard.ChangeOrder') }}
        </v-btn>
        <v-btn
          v-show="ui.editRowOrder"
          text
          tile
          color="#68648B"
          class="DashboardManage__row-order-control"
          @click="ui.editRowOrder = false; initRowOrder()"
        >
          {{ $t('dashboard.Cancel') }}
        </v-btn>
      </div>
    </div>

    <VueDraggable
      v-show="currentTabCards.model && currentTabCards.model.length"
      :key="currentTabCards.key"
      v-model="currentTabCards.model"
      :handle="'.DashboardCard__drag-handle' && null /* ignore handle, let grab anything */"
      draggable=".DashboardManage__card"
      :animation="500"
      :disabled="ui.saving"
      :ghost-class="'DashboardManage__card--ghost'"
      class="DashboardManage__cards"
      :class="'DashboardManage__cards' + currentTabCards.key"
    >
      <!-- list of saved and unsaved cards (during editing) -->
      <DashboardCard
        v-for="card in currentTabCards.model"
        :key="card.id"
        :project-id="projectId"
        :card="card"
        :disabled="ui.loading || ui.saving"
        edit
        class="DashboardManage__card"
        @change="onCardChange($event)"
        @delete="onCardDelete(card.id)"
        @swap-with-previous-card="swapCardWithPrevious(currentTabCards, card)"
        @swap-with-next-card="swapCardWithNext(currentTabCards, card)"
      />
    </VueDraggable>
  </div>
</template>

<script>
import * as R from 'ramda'
import VueDraggable from 'vuedraggable'

import {
  BG,
  CARD_TYPE,
  COLORS,
  DASHBOARD,
} from '../constants'
import {
  addEventListenerBeforeDestroy,
  pushRoute,
  randomChoice,
  replaceRoute,
  reportError,
} from '../helpers'

import Dashboard from '../store/orm/dashboard'
import DashboardCard from '../store/orm/dashboardCard'
import Issue from '../store/orm/issue'
import Project from '../store/orm/project'

import DashboardCardComponent from '../components/DashboardCard'
import AppBarLayout from '../layouts/AppBarLayout'

const defaultCardMetaData = () => ({
  position: null,
  color: randomChoice(COLORS),
  pattern: randomChoice(Object.keys(BG)),
})

const defaultModel = (crossProject) => ({
  // We *plan* changes and then apply them on `Save` click.
  // Planned changes to dashboard cards:
  deleteCards: [], // Array<int>
  updateCards: [], // Array<Card>
  rowOrder: [...(crossProject
    ? DASHBOARD.CROSS_PROJECT_DEFAULT_ROWS
    : DASHBOARD.PROJECT_DEFAULT_ROWS)],
  cardOrder: {
    [DASHBOARD.SIMPLE_COUNTER_ROW]: [],
    [DASHBOARD.AB_COUNTER_ROW]: [],
    [DASHBOARD.PIE_ROW]: [],
    [DASHBOARD.TABLE_ROW]: [],
    [DASHBOARD.CHECKLIST_STATUS]: [],
  },
})

export default {
  name: 'DashboardManage',

  components: {
    VueDraggable,

    AppBarLayout,
    DashboardCard: DashboardCardComponent,
  },

  metaInfo() {
    const { $store, projectId, dashboard, project } = this
    return {
      title: projectId
        ? $store.getters.title(dashboard
          ? this.$t('dashboard.ManageDashboard')
          : this.$t('dashboard.Loading'))
        : $store.getters.title(dashboard && project
          ? `${this.$t('dashboard.ManageDashboard')} – ${project.name}`
          : this.$t('dashboard.Loading')),
    }
  },

  props: {
    projectId: { type: String, default: null },
  },

  data() {
    return {
      SIMPLE_COUNTER_ROW: DASHBOARD.SIMPLE_COUNTER_ROW,
      AB_COUNTER_ROW: DASHBOARD.AB_COUNTER_ROW,
      PIE_ROW: DASHBOARD.PIE_ROW,
      TABLE_ROW: DASHBOARD.TABLE_ROW,
      CHECKLIST_STATUS: DASHBOARD.CHECKLIST_STATUS,

      TAB_NAMES: Object.freeze({
        [DASHBOARD.SIMPLE_COUNTER_ROW]: this.$t('dashboard.TabCounters'),
        [DASHBOARD.AB_COUNTER_ROW]: this.$t('dashboard.TabSubset'),
        [DASHBOARD.PIE_ROW]: this.$t('dashboard.TabPie'),
        [DASHBOARD.TABLE_ROW]: this.$t('dashboard.TabTables'),
        [DASHBOARD.CHECKLIST_STATUS]: this.$t('dashboard.TabChecklists'),
      }),

      model: defaultModel(this.projectId == null),

      ui: {
        currentTab: DASHBOARD.SIMPLE_COUNTER_ROW,
        editRowOrder: false,
        leavePageConsent: false,
        loading: false,
        saving: false,
      },
    }
  },

  computed: {
    dashboardId() { return this.projectId || Dashboard.GLOBAL_ID },

    project() {
      const { projectId } = this
      return projectId && Project.find(projectId)
    },

    // dashboard is a set of cards (may change in the future)
    dashboard() {
      const { dashboardId } = this
      return Dashboard.query().with('cards').find(dashboardId)
    },

    dashboardRoute() {
      const { projectId } = this
      return projectId
        ? {
          name: 'ProjectDashboard',
          params: { projectId },
        }
        : { name: 'Dashboard' }
    },

    // cards grouped by card type:
    // 1) Simple counters;
    // 2) A/B counters;
    // 2) pie charts;
    // 3) tables.
    cardsByType() {
      const { dashboard } = this
      return dashboard && dashboard.orderedCards && {
        [DASHBOARD.SIMPLE_COUNTER_ROW]: dashboard.orderedCards.filter(card =>
          this.getUpdatedCard(card.id).cardType === CARD_TYPE.counter),

        [DASHBOARD.AB_COUNTER_ROW]: dashboard.orderedCards.filter(card =>
          this.getUpdatedCard(card.id).cardType === CARD_TYPE.aOfB),

        [DASHBOARD.PIE_ROW]: dashboard.orderedCards.filter(card =>
          this.getUpdatedCard(card.id).cardType === CARD_TYPE.pieChart),

        [DASHBOARD.TABLE_ROW]: dashboard.orderedCards.filter(card =>
          this.getUpdatedCard(card.id).cardType === CARD_TYPE.table),

        [DASHBOARD.CHECKLIST_STATUS]: dashboard.orderedCards.filter(card =>
          this.getUpdatedCard(card.id).cardType === CARD_TYPE.checklistStatus),
      }
    },

    // current tab cards with associated model for vue-draggable reordering
    currentTabCards() {
      const { ui: { currentTab }, cardsByType, model: { deleteCards } } = this
      const self = this

      return {
        key: currentTab,
        cards: cardsByType?.[currentTab]?.filter?.(card =>
          !deleteCards.includes(card.id)) || null,

        // get-set models for draggable
        get model() { return self[`model${currentTab}`] },
        set model(value) { self[`model${currentTab}`] = value },
      }
    },

    // has the model changed?
    // NB! drag-n-drop & reordering changes may be applied lazily
    // and may require `applyNewCardOrderToModel` call for
    // `hasChanges` to return the correct result
    hasChanges() {
      const {
        model: { rowOrder, deleteCards, updateCards },
        dashboard,
        projectId,
      } = this

      const prevRowOrder = dashboard?.metaData?.rowOrder ||
        [...(projectId == null
          ? DASHBOARD.CROSS_PROJECT_DEFAULT_ROWS
          : DASHBOARD.PROJECT_DEFAULT_ROWS)]
      if (!R.equals(rowOrder, prevRowOrder)) return true

      return !!(deleteCards.length || updateCards.length)
    },

    // draggable model definitions for each row and the column
    ...[
      DASHBOARD.SIMPLE_COUNTER_ROW,
      DASHBOARD.AB_COUNTER_ROW,
      DASHBOARD.PIE_ROW,
      DASHBOARD.TABLE_ROW,
      DASHBOARD.CHECKLIST_STATUS,
    ].reduce((computed, cardTypeKey) => {
      computed[`model${cardTypeKey}`] = {
        get() {
          return this.model.cardOrder[cardTypeKey]
            .filter(cardId => !this.model.deleteCards.includes(cardId))
            .map(this.getUpdatedCard)
        },
        set(reorderedCards) {
          this.model.cardOrder[cardTypeKey] =
            R.map(R.prop('id'), reorderedCards)
        },
      }
      return computed
    }, {}),
  },

  watch: {
    // initialize state and start loading data on new `projectId` change
    projectId: {
      handler() {
        this.model = defaultModel(this.projectId == null)
        this.initRowOrder()
        this.loadData()
      },
      immediate: true,
    },

    // update `model`.`rowOrder` when dashboard has been loaded,
    // then save card order to the model
    dashboard: ['initRowOrder', 'initCardOrder'].map(handler => ({
      handler,
      immediate: true,
    })),

    // show "card deleted [UNDO]" snackbar
    'model.deleteCards': 'toggleCardDeletedSnackbar',
  },

  mounted() {
    this.setupWatchers()
  },

  methods: {
    last: R.last,

    // Setup watchers for events outside this component:
    // * App bar btn clicks
    // * Snackbar action btn click
    // * window.onbeforeunload
    // * Vue-router navigation guard
    setupWatchers() {
      // * window.onbeforeunload
      addEventListenerBeforeDestroy(
        this, window, 'beforeunload', this.onBeforeUnload)

      // * Vue-router navigation guard
      const unsub = this.$router.beforeEach(this.navigationGuard)
      this.$once('hook:beforeDestroy', unsub)
    },

    revertChanges() {
      if (this.checkHasChanges()) {
        this.revertOrApplyChangesWithPrompt()
      } else {
        this.goToDashboard()
      }
    },

    undoCardDelete() {
      this.model.deleteCards = []
    },

    async loadData() {
      this.ui.loading = true

      try {
        await this.loadProjects()
        const promises = [this.loadDashboard()]
        if (this.project) {
          promises.push(
            this.loadDashboard(),
            this.loadChecklists(),
            this.loadIssueStatuses(),
          )
        }
        await Promise.all(promises)
      } catch (e) {
        reportError(e).catch(e => console.error(e))
      } finally {
        this.ui.loading = false
      }
    },

    loadProjects() { return Project.dispatch('$getList') },
    loadChecklists() {
      const { $store, projectId } = this
      return $store.dispatch('checklist/getChecklist', { projectId })
    },
    loadDashboard() {
      const { dashboardId } = this
      return Dashboard.dispatch('$get', { id: dashboardId })
        // no promise returned: value loading should be non-blocking
        .then(() => {
          this.loadCardValues()
          this.maybeLoadTotalIssues()
        })
    },
    loadCardValues() {
      const { dashboard, dashboardId } = this
      return dashboard
        ? Promise.all(
          dashboard.cards.map(({ id: cardId }) =>
            DashboardCard.dispatch('$getDetails', { dashboardId, cardId })),
        )
        : Promise.reject(new Error("project or dashboard isn't loaded"))
    },
    async maybeLoadTotalIssues() {
      const { dashboard, projectId } = this
      if (!dashboard) throw new Error("project or dashboard isn't loaded")

      const hasCounters = dashboard.cards
        .some(card => card.cardType === CARD_TYPE.counter)
      if (hasCounters) await Issue.dispatch('$getTotal', { projectId })
    },
    loadIssueStatuses() {
      const { $store, projectId } = this
      if (!projectId) throw new Error('Cannot load issue status outside project')
      return $store.dispatch('$issueStatus/getList', { projectId, reload: false })
    },

    // get saved (reference) card from the app storage
    getCard(cardId) {
      return DashboardCard.find(cardId)
    },

    // searches `model`.`updateCards` for a card with `cardId`,
    // if there's none, returns `getCard(cardId)`
    getUpdatedCard(cardId) {
      const { model: { updateCards } } = this
      return R.find(R.propEq('id', cardId), updateCards) ||
        this.getCard(cardId)
    },

    // moves card left
    swapCardWithPrevious(cards, card) {
      const ix = cards.model.indexOf(card)
      cards.model = R.move(ix, ix - 1, cards.model)
    },

    // moves card right
    swapCardWithNext(cards, card) {
      const ix = cards.model.indexOf(card)
      cards.model = R.move(ix, ix + 1, cards.model)
    },

    // moves tab with card type right
    swapRowWithNext(rowKey) {
      const ix = this.model.rowOrder.indexOf(rowKey)
      this.model.rowOrder = R.move(ix, ix + 1, this.model.rowOrder)
    },

    // existing card has got a planned update,
    // remove previous updates for `card` from `model`.`updateCards`,
    // then add `card` to `model`.`updateCards` (only if any field
    // has changed compared to the initial card state).
    onCardChange(card) {
      const { model } = this

      const removeOldCard = R.reject(R.propEq('id', card.id))
      const appendNewCard = R.append(card)
      const referenceCard = this.getCard(card.id)

      const operations = R.equals(card, referenceCard)
        ? [removeOldCard] // after all changes the card is the same again
        : [removeOldCard, appendNewCard] // card has changed and has new values

      model.updateCards = R.pipe(...operations)(model.updateCards)
    },

    // existing card is planned for deletion,
    // add `cardId` to `model`.`deleteCards`
    onCardDelete(cardId) {
      const { model } = this
      model.deleteCards = R.pipe(
        R.append(cardId),
        R.uniq,
      )(model.deleteCards)
    },

    // on cards deleted/restored, if we have at least 1 card to be deleted,
    // displaying a snackbar "(N) card(s) was/were deleted [UNDO]"
    toggleCardDeletedSnackbar() {
      const { $store, model: { deleteCards } } = this

      if (deleteCards.length) {
        $store.commit('$snackbar/setMessage', {
          message: this.$tc('dashboard.CardWasDeletedN', deleteCards.length),
          timeout: -1,
          action: {
            type: 'function',
            label: this.$t('dashboard.Undo'),
            fn: () => this.undoCardDelete(),
            once: true,
          },
        })
      } else {
        $store.commit('$snackbar/setMessage', { message: null })
      }
    },

    // return the last saved row order of the current dashboard,
    // uses fallback if the value isn't loaded or is invalid
    validatedRowOrder() {
      const { projectId, dashboard } = this
      const rowOrder = dashboard?.metaData?.rowOrder
      const fallback = [...(projectId
        ? DASHBOARD.PROJECT_DEFAULT_ROWS
        : DASHBOARD.CROSS_PROJECT_DEFAULT_ROWS)]
      // validates that rowOrder makes sense in terms of values
      return R.equals(
        R.sortBy(R.identity, rowOrder || []),
        R.sortBy(R.identity, fallback),
      )
        ? rowOrder
        : fallback
    },

    // this is meant to be called when `dashboard` was loaded,
    // not during row reordering afterwards
    initRowOrder() {
      this.model.rowOrder = this.validatedRowOrder()
    },

    initCardOrder() {
      const { cardsByType } = this

      const pickIds = R.map(R.prop('id'))

      this.model.cardOrder = cardsByType
        ? R.map(pickIds, cardsByType)
        : defaultModel(this.projectId == null).cardOrder
    },

    // sync `model`.`cardOrder` to `metaData`.`position`
    // of cards in `model`.`updateCards`
    applyNewCardOrderToModel() {
      const {
        model: { cardOrder, updateCards, deleteCards },
      } = this

      const setPositionMut = (card, position) => {
        card.metaData = card.metaData || defaultCardMetaData()
        card.metaData.position = position
      }
      const setPosition = (card, position) => {
        const updatedCard = R.clone(card)
        setPositionMut(updatedCard, position)
        return updatedCard
      }

      const unchangedCardIds = new Set()
      Object
        .values(cardOrder)
        .forEach(cardIds => cardIds
          .forEach((cardId, ix) => {
            const newPosition = ix * 10

            let card = null
            if (deleteCards.includes(cardId)) return // skip deleted cards

            card = R.find(R.propEq('id', cardId), updateCards) || null
            const referenceCard = this.getCard(cardId)
            if (card === null) {
              // card doesn't have updates besides maybe position
              const updatedCard = setPosition(referenceCard, newPosition)

              if (!R.equals(updatedCard, referenceCard)) {
                updateCards.push(updatedCard)
              } else {
                unchangedCardIds.add(cardId)
              }
            } else {
              // card has some other updates
              const updatedCard = setPosition(card, newPosition)

              if (R.equals(updatedCard, referenceCard)) {
                unchangedCardIds.add(cardId)
              } else {
                setPositionMut(card, newPosition)
              }
            }
          }))

      this.model.updateCards = R.reject(
        card => unchangedCardIds.has(card.id),
        this.model.updateCards,
      )
    },

    // saving model (update/create/delete all the cards)
    async applyChanges() {
      // update position of the cards based on the current drag-n-drop result
      this.applyNewCardOrderToModel()

      const { updateCards, deleteCards, rowOrder } = this.model

      this.ui.saving = true
      this.ui.editRowOrder = false

      // we are performing multiple requests,
      // if something goes wrong we have to try to rollback
      // partially created/updated/deleted cards
      let rollbackStarted = false
      let rollback = () => Promise.resolve([])

      try {
        // update cards
        await Promise.all(updateCards.map(card => {
          // for each `update` plan the opposite update for rollback scenario
          const referenceCard = this.getCard(card.id)
          const revertUpdate = () =>
            this.updateCard(referenceCard)
              .catch(reportError)

          return this.updateCard(card)
            .then(() => {
              // rollback is started before this request was completed
              if (rollbackStarted) return revertUpdate()

              // if rollback will be performed later it'll also revert the update
              const otherRollbackActions = rollback
              rollback = () =>
                Promise.all([otherRollbackActions(), revertUpdate()])
            })
        }))

        // delete cards
        await Promise.all(deleteCards.map(cardId => {
          // for each `delete` plan the rollback: re-create card
          const referenceCard = this.getCard(cardId)
          const recreateCard = () =>
            this.createCard(referenceCard)
              .catch(reportError)

          return this.deleteCard(cardId)
            .then(() => {
              if (rollbackStarted) return recreateCard()

              const otherRollbackActions = rollback
              rollback = () =>
                Promise.all([otherRollbackActions(), recreateCard()])
            })
        }))

        // save row order
        // 1) plan a rollback for reverting the row order
        const revertRowOrder = () =>
          this.saveRowOrder({
            reloadDashboard: false,
            rowOrder: this.validatedRowOrder(),
          })
        // 2) does save the row order `model`.`rowOrder`
        await this.saveRowOrder({ reloadDashboard: false, rowOrder })
          .then(() => {
            if (rollbackStarted) return revertRowOrder()

            const otherRollbackActions = rollback
            rollback = () =>
              Promise.all([otherRollbackActions(), revertRowOrder()])
          })

        // test slow network, ui should be disabled during `applyChanges`
        // await new Promise(resolve => setTimeout(resolve, 15 * 1000))

        // done; go to dashboard
        this.goToDashboard()
      } catch (e) {
        reportError(e).catch(e => console.error(e))

        rollbackStarted = true
        try {
          await rollback()
        } catch (e) {
          reportError(e).catch(e => console.error(e))
        }
      } finally {
        this.ui.saving = false
      }
    },

    // proxy methods for vuex-actions
    createCard(card, commitChanges = false) {
      const { dashboardId } = this
      const payload = { dashboardId, card, commitChanges }
      return DashboardCard.dispatch('$create', payload)
    },
    updateCard(card, commitChanges = false) {
      const { dashboardId } = this
      const payload = { dashboardId, cardId: card.id, card, commitChanges }
      return DashboardCard.dispatch('$update', payload)
    },
    deleteCard(cardId, commitChanges = false) {
      const { dashboardId } = this
      const payload = { dashboardId, cardId, commitChanges }
      return DashboardCard.dispatch('$delete', payload)
    },
    saveRowOrder({ reloadDashboard = false, rowOrder } = {}) {
      const { dashboardId, dashboard } = this
      return Dashboard.dispatch('$update', {
        id: dashboardId,
        dashboard: {
          metaData: {
            ...(dashboard?.metaData || {}),
            rowOrder,
          },
        },
        reloadDashboard,
      })
    },

    // handle page leave
    checkHasChanges() {
      this.applyNewCardOrderToModel()
      return this.hasChanges
    },

    goToDashboard({ replace = false } = {}) {
      this.leavePageConsent = true;
      (replace ? replaceRoute : pushRoute)(this.$router, this.dashboardRoute)
    },

    async revertOrApplyChangesWithPrompt(goToDashboard = true) {
      let save = false
      const discardAccepted = await this.$store.dispatch('confirm/openDialog', {
        title: this.$t('dashboard.DiscardChangesQ'),
        subtitle: this.$t('dashboard.LeavingWithoutSavingM'),
        consentLabel: this.$t('dashboard.DiscardChanges'),
        rejectLabel: this.$t('dashboard.Save'),
        customOnRejectBtnClick: (_, doReject) => { save = true; doReject() },
        maxWidth: 456,
      })
      if (!discardAccepted && !save) return false
      this.leavePageConsent = true
      if (save) await this.applyChanges()
      if (goToDashboard) this.goToDashboard()
      return true
    },

    shouldShowDiscardChanges() {
      const cypressValue =
        localStorage.getItem('cypress:dashboard-discard-changes')

      if (cypressValue === 'force') return true
      if (cypressValue === 'skip') return false
      return !this.leavePageConsent && this.checkHasChanges()
    },

    // browser page close/refresh
    onBeforeUnload(e) {
      const msg = this.shouldShowDiscardChanges()
        ? `${this.$t('dashboard.DiscardChangesQ')} ${this.$t('dashboard.LeavingWithoutSavingM')}`
        : null

      // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
      if (msg) {
        e.preventDefault()
        e.returnValue = msg
        return msg
      } else {
        delete e.returnValue
        return null
      }
    },

    // vue-router navigation
    navigationGuard(to, from, next) {
      if (this.shouldShowDiscardChanges()) {
        this.revertOrApplyChangesWithPrompt(false)
          .then(reverted => reverted && pushRoute(this.$router, to))
        next(new Error('prevent navigation'))
      } else {
        next()
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../scss/variables'

.DashboardManage
  position: relative
  background: #EAEEF6
  padding: 44px 56px
  min-height: 100%

  &__controls
    margin-bottom: 26px
    display: flex

  &__tab-wrapper
    display: inline-block

  &__tab
    $border-width: 2px
    $border-color: #0088FF
    $inactive-color: #8B90A0 // Text/Secondary
    $active-color: #38364D // Text / primary
    $muted-color: #C4C7D5 // Text/disabled

    ::v-deep .v-btn__content
      font-weight: normal
      font-size: 14px
      line-height: 170%
      color: $inactive-color

    &--active:not([disabled])
      position: relative

      ::v-deep .v-btn__content
        color: $active-color

      &:after, &::after
        content: ''
        display: block
        position: absolute
        left: 0
        right: 0
        bottom: 0
        border-top: $border-width solid $border-color

  &__row-order-controls
    margin-left: 16px
    height: 64px
    display: inline-flex
    align-items: center

  &__row-order-control
    font-weight: 500
    font-size: 14px
    line-height: 16px // identical to box height, or 114%
    letter-spacing: 0.5px

  &__cards
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(208px, 1fr))
    flex-wrap: wrap
    align-items: stretch
    grid-gap: 16px

    &--pies
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr))

    &--tables
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr))

  &__card
    min-height: 151px
    min-width: 208px

    &::v-deep
      .DashboardCardTypeChecklist
        min-height: 151px

    &--ghost
      opacity: .3
      outline: 1px dotted black

    &.DashboardCard--checklist
        min-width: 320px !important
</style>
